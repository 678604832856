// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable jsx-a11y/control-has-associated-label */

import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { Upload, message, Button as AntdButton, Rate } from 'antd';
import axios from 'axios';
import { RcFile, UploadChangeParam } from 'antd/es/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GrPrevious, GrNext } from 'react-icons/gr';
import { RxCross1 } from 'react-icons/rx';

import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Button from '../../../../components/bootstrap/Button';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import Select from '../../../../components/bootstrap/forms/Select';
import {
	usePostOnBoardingMutation,
	useGetPresignedUrlMutation,
} from '../../../../services/OnBoardingApis/OnBoardingApi';
import {
	CategoryData,
	CategoryResponse,
	MasseusesData,
	MasseusesListResponse,
	PresignedResponse,
	ServiceResponse,
} from '../../../../services/DashboardApis/type';
import { OnBoardingResponse } from '../../../../services/OnBoardingApis/type';
import { setOnBoardingStep } from '../../../../redux/slice';
import {
	useAddServiceMutation,
	useGetCategoriesMutation,
	useGetMasseuseListMutation,
} from '../../../../services/DashboardApis/dashboardApi';
import Loader from '../../../../components/Loader';
import { RootState } from '../../../../redux/store';

export interface Availability {
	day: string;
	time: {
		startTime: string;
		endTime: string;
	};
}

interface Masseuse {
	id: string;
	image: string;
	name: string;
	rating: number;
	description: string;
	gender: string;
}

interface ServiceForm {
	category: string;
	name: string;
	serviceImage: string;
	description: string;
	pricingData: {
		duration: string;
		price: string;
	}[];
	availability: Availability[];
	masseuses: string[];
}

const OnBoardServiceManagement = () => {
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [fileData, setFileData] = useState<any[]>([]);
	const [selectedMasseuses, setSelectedMasseuses] = useState<string[]>([]);
	const [serviceData, setServiceData] = useState<any>({
		category: '',
		name: '',
		serviceImage: '',
		description: '',
		masseuses: [],
		pricingData: [{ price: 0, duration: 0 }],
		availability: [],
	});
	const [currentIndex, setCurrentIndex] = useState(0);
	const itemsToShow = 3;
	const [categories, setCategories] = useState<CategoryData[]>();
	const [serviceForms, setServiceForms] = useState<ServiceForm[]>([
		{
			category: '',
			name: '',
			serviceImage: '',
			description: '',
			pricingData: [{ duration: '', price: '' }],
			availability: [{ day: '', time: { startTime: '', endTime: '' } }],
			masseuses: [],
		},
	]);
	const [getMasseuseList] = useGetMasseuseListMutation();
	const [masseuses, setMasseuses] = useState<MasseusesData[]>();
	const [isSaveComplete, setIsSaveComplete] = useState(false);
	const { id } = useSelector((state: RootState) => state.admin);

	const [getCategories] = useGetCategoriesMutation();
	const [addService] = useAddServiceMutation();
	const [postOnBoarding] = usePostOnBoardingMutation();

	const addServiceForm = () => {
		setServiceForms([
			...serviceForms,
			{
				category: '',
				name: '',
				serviceImage: '',
				description: '',
				pricingData: [{ duration: '', price: '' }],
				availability: [],
				masseuses: [],
			},
		]);
	};

	const removeServiceForm = (index: number) => {
		const updatedForms = [...serviceForms];
		updatedForms.splice(index, 1);
		setServiceForms(updatedForms);
	};

	const handleInputChange = (index: number, field: keyof ServiceForm, value: any) => {
		const updatedForms = [...serviceForms];
		updatedForms[index][field] = value;
		setServiceForms(updatedForms);
	};

	const handlePricingChange = (
		formIndex: number,
		pricingIndex: number,
		field: keyof ServiceForm['pricingData'][0],
		value: any,
	) => {
		const updatedForms = [...serviceForms];
		updatedForms[formIndex].pricingData[pricingIndex][field] = value;
		setServiceForms(updatedForms);
	};

	const handleAvailabilityChange = (
		formIndex: number,
		availabilityIndex: number,
		field: 'day' | 'time',
		value: any,
		subField?: 'startTime' | 'endTime',
	) => {
		const updatedForms = [...serviceForms];
		if (field === 'time' && subField) {
			updatedForms[formIndex].availability[availabilityIndex].time[subField] = value;
		} else {
			updatedForms[formIndex].availability[availabilityIndex][field] = value;
		}
		setServiceForms(updatedForms);
	};

	const handleCardClick = (formIndex: number, masseuseId: string) => {
		setServiceForms((prevServiceForms) => {
			return prevServiceForms.map((service, index) => {
				if (index === formIndex) {
					const isSelected = service.masseuses.includes(masseuseId);

					return {
						...service,
						masseuses: isSelected
							? // eslint-disable-next-line @typescript-eslint/no-shadow
								service.masseuses.filter((id) => id !== masseuseId)
							: [...service.masseuses, masseuseId],
					};
				}
				return service;
			});
		});
	};

	const handleNext = () => {
		// eslint-disable-next-line no-unsafe-optional-chaining
		if (masseuses && currentIndex < masseuses?.length - itemsToShow) {
			setCurrentIndex(currentIndex + 1);
		}
	};

	const handlePrevious = () => {
		if (currentIndex > 0) {
			setCurrentIndex(currentIndex - 1);
		}
	};

	const [getPresignedUrl] = useGetPresignedUrlMutation();

	const addPricingInput = (formIndex: number) => {
		const updatedForms = [...serviceForms];
		const newPricingInput = {
			price: '',
			duration: '',
		};

		updatedForms[formIndex].pricingData.push(newPricingInput);
		setServiceForms(updatedForms);
	};

	const deletePricing = (formIndex: number, pricingIndex: number) => {
		const updatedForms = [...serviceForms];
		updatedForms[formIndex].pricingData.splice(pricingIndex, 1);
		setServiceForms(updatedForms);
	};

	const uploadButton = (
		<button style={{ border: 0, background: 'none' }} type='button'>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</button>
	);

	const uploadDocumentsHandler = async (info: UploadChangeParam, index: number) => {
		const selectedDoc = info.file;
		setFileData(info.fileList);

		if (!selectedDoc) {
			message.error('Selected document is undefined');
			return;
		}

		await getPresignedUrl({
			filename: info.file.name,
			keyType: 'serviceImage',
		})
			.unwrap()
			.then(async (response: any) => {
				updateDocumentHandler({
					presignedData: response.data,
					selectedDoc: selectedDoc as RcFile,
					index,
				});
			})
			.catch((err: any) => {
				message.error(err.error);
			});
	};

	const daysOfWeek = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];

	const handleAddAvailability = (formIndex: number) => {
		const updatedForms = [...serviceForms];
		const newAvailability = {
			day: '',
			time: {
				startTime: '',
				endTime: '',
			},
		};

		updatedForms[formIndex].availability.push(newAvailability);
		setServiceForms(updatedForms);
	};

	const getCategoryHandler = useCallback(async () => {
		setIsLoading(true);
		await getCategories({})
			.unwrap()
			.then((response) => {
				const { result, message: msg, data } = response as CategoryResponse;
				if (result) {
					setCategories(data);
					console.log(msg);
					setIsLoading(false);
				}
				console.log('🚀  .then  data:', data);
			})
			.catch((error) => {
				message.error(error?.data?.message);
				console.error(error);
				setIsLoading(false);
			});
	}, [getCategories]);

	const getMasseusesHandler = useCallback(async () => {
		setIsLoading(true);
		await getMasseuseList({ centerId: id })
			.unwrap()
			.then(async (response) => {
				const {
					data: masseuse,
					message: msg,
					pagination,
				} = response as MasseusesListResponse;
				if (masseuse) {
					setMasseuses(masseuse);
					setIsLoading(false);
				}
			})
			.catch((err) => {
				console.log('🚀  getMasseuseData  err:', err);
				setIsLoading(false);
				message.error(err?.data?.message);
			});
	}, [getMasseuseList, id]);

	useEffect(() => {
		getCategoryHandler();
		getMasseusesHandler();
	}, [getCategoryHandler, getMasseusesHandler]);

	// *************************************************************
	// NOTE: Helper Methods
	// *************************************************************

	const updateDocumentHandler = async ({
		presignedData,
		selectedDoc,
		index,
	}: {
		presignedData: PresignedResponse;
		selectedDoc: RcFile;
		index: number;
		// eslint-disable-next-line consistent-return
	}) => {
		if (!presignedData && !selectedDoc) {
			return message.error('Something went wrong please try again later');
		}
		setServiceData({ ...serviceData, profileImage: presignedData?.filename });

		const updatedForms = [...serviceForms];
		// eslint-disable-next-line @typescript-eslint/dot-notation
		updatedForms[index]['serviceImage'] = presignedData?.filename;
		setServiceForms(updatedForms);

		await axios
			.put(`${presignedData.presignedUrl}`, selectedDoc)
			.then(() => {
				message.success('Successfully uploaded');
			})
			.catch(() => {
				message.error('Something went wrong...');
			});
	};

	const handleThirdStepSubmit = () => {
		try {
			postOnBoarding({
				onboardingStep: 3,
			})
				.unwrap()
				.then(async (res) => {
					const { result, message: msg, data } = res as OnBoardingResponse;
					if (result) {
						message.success(msg);
						dispatch(setOnBoardingStep(4));
					} else {
						message.error(msg);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
				});
		} catch (error) {
			message.error('something went wrong');
			console.log(error);
		}
	};

	const addServiceHandler = async (service: ServiceForm) => {
		setIsLoading(true);
		await addService({ payload: service, centerId: id })
			.unwrap()
			.then((response) => {
				const { result, message: msg } = response as ServiceResponse;
				if (result) {
					message.success(msg);
					setIsSaveComplete(true);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				message.error(error?.data?.message);
				setIsLoading(false);
			});
	};

	return (
		<div className='row col-12 d-flex justify-content-center'>
			<div className='d-flex flex-column col-10 align-items-center justify-content-center py-5'>
				<p className='fs-3 mb-6 fw-semibold col-10 text-start'>Add Service</p>
				<div className='row col-12 d-flex row-gap-4 justify-content-center'>
					{serviceForms.map((service: ServiceForm, formIndex: number) => (
						<div className='d-flex flex-column  align-items-center col-10 px-3 py-4 border border-2 rounded-2'>
							{serviceForms?.length > 1 && (
								<div className='row col-12 d-flex align-items-center justify-content-end'>
									<button
										type='button'
										className='d-flex align-items-center justify-content-center rounded-2 col-1 py-2 btn btn-primary border-1'
										onClick={() => removeServiceForm(formIndex)}>
										<RxCross1 size={20} />
									</button>
								</div>
							)}
							<div className='row g-4 mt-4 col-12'>
								<div className='col-6'>
									<p className='m-lg-1 fw-bold'>Service Image</p>
									<Upload
										accept='.jpg, .jpeg, .png'
										listType='picture-card'
										beforeUpload={() => false}
										multiple
										rootClassName='w-10'
										onChange={(info) =>
											uploadDocumentsHandler(info, formIndex)
										}>
										{fileData.length >= 30 ? null : uploadButton}
									</Upload>
								</div>
							</div>
							<div className='row g-4 mt-4 col-12'>
								<div className='col-6'>
									<p className='m-lg-1 fw-bold'>Name</p>
									<div className='col-12'>
										<FormGroup id='serviceName' isFloating>
											<Input
												value={service.name}
												onChange={(e: any) => {
													handleInputChange(
														formIndex,
														'name',
														e.target.value,
													);
												}}
											/>
										</FormGroup>
									</div>
								</div>
								<div className='col-6'>
									<p className='m-lg-1 fw-bold'>Category</p>
									<div className='col-12'>
										<FormGroup id='serviceCategory' isFloating>
											<select
												className='form-select'
												value={service.category}
												onChange={(e) =>
													handleInputChange(
														formIndex,
														'category',
														e.target.value,
													)
												}>
												<option value=''>Select Category</option>
												{categories?.map((category, index) => (
													/* eslint-disable-next-line react/no-array-index-key */
													<option key={index} value={category.id}>
														{category.name}
													</option>
												))}
											</select>
										</FormGroup>
									</div>
								</div>
							</div>
							<div className='row g-4 mt-4 col-12'>
								<p className='m-lg-1 fw-bold'>Description</p>
								<div className='col-12'>
									<FormGroup id='description' className='col-md-'>
										<Textarea
											value={service?.description}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												handleInputChange(
													formIndex,
													'description',
													e.target.value,
												)
											}
										/>
									</FormGroup>
								</div>
							</div>
							<div className='row g-4 mt-4 col-12 d-flex justify-content-center row-gap-2'>
								<div className='row col-12 d-flex flex-row justify-content-between align-items-center'>
									<label htmlFor='data' className='fw-bold text-start col-3'>
										Pricing Data
									</label>
									<button
										type='button'
										className='col-2 btn btn-success'
										onClick={() => addPricingInput(formIndex)}>
										+ Add
									</button>
								</div>
								<div className='col-12'>
									<div
										style={{
											borderRadius: '20px',
											marginLeft: 'auto',
											marginRight: 'auto',
											padding: '8px',
										}}
										className='row mt-2 border border-2 rounded-sm'>
										{service.pricingData.map((owner: any, index: any) => (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
												className='col-md-12 row'>
												<FormGroup
													className='col-6'
													labelClassName='mt-2'
													id={`ownerName_${index}`}
													label='Duration'
													isFloating>
													<Input
														placeholder='Duration'
														style={{
															padding: '10px',
															height: '50px',
															paddingTop: '25px',
														}}
														autoComplete='duration'
														className='mt-2'
														name='duration'
														value={
															owner.duration !== null &&
															!isNaN(owner.duration)
																? owner.duration.toString()
																: ''
														}
														onChange={(
															e: ChangeEvent<HTMLInputElement>,
														) =>
															handlePricingChange(
																formIndex,
																index,
																'duration',
																e.target.value,
															)
														}
													/>
												</FormGroup>
												<FormGroup
													id={`ownerPercentage_${index}`}
													label='Price'
													className='mt-2 col-5'
													labelClassName='mt-0'
													isFloating>
													<Input
														placeholder='Price'
														autoComplete='price'
														name='price'
														style={{
															padding: '10px',
															height: '50px',
															paddingTop: '25px',
														}}
														value={
															owner.price !== null &&
															!isNaN(owner.price)
																? owner.price.toString()
																: ''
														}
														onChange={(
															e: ChangeEvent<HTMLInputElement>,
														) =>
															handlePricingChange(
																formIndex,
																index,
																'price',
																e.target.value,
															)
														}
													/>
												</FormGroup>
												{service?.pricingData?.length > 1 && (
													<div
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
														className='col-md-1'>
														<AntdButton
															className='btn'
															onClick={() =>
																deletePricing(formIndex, index)
															}>
															<FaTrash />
														</AntdButton>
													</div>
												)}
											</div>
										))}
									</div>
								</div>
							</div>
							<div className='row g-4 mt-4 col-12 d-flex justify-content-center row-gap-2'>
								<div className='row col-12 d-flex flex-row justify-content-between align-items-center'>
									<label
										htmlFor='availability'
										className='fw-bold text-start col-3'>
										Availability
									</label>
									<button
										type='button'
										className='col-2 btn btn-success'
										onClick={() => handleAddAvailability(formIndex)}>
										+ Add
									</button>
								</div>
								<div className='row col-12 d-flex justify-content-center row-gap-3 p-4 border border-2 rounded-2'>
									{/* eslint-disable-next-line react/no-array-index-key */}
									{service.availability.map((availability, index) => (
										<div
											/* eslint-disable-next-line react/no-array-index-key */
											key={index}
											className='row p-3 border border rounded-2'>
											<div className='col-md-4'>
												<label htmlFor='day'>Day</label>
												<select
													className='form-select'
													value={availability.day}
													onChange={(e) =>
														handleAvailabilityChange(
															formIndex,
															index,
															'day',
															e.target.value,
														)
													}>
													<option value=''>Select Day</option>
													{daysOfWeek.map((day) => (
														<option key={day} value={day}>
															{day}
														</option>
													))}
												</select>
											</div>
											<div className='col-md-4'>
												<label htmlFor='time'>Start Time</label>
												<input
													type='time'
													className='form-control'
													value={availability.time.startTime}
													onChange={(e) =>
														handleAvailabilityChange(
															formIndex,
															index,
															'time',
															e.target.value,
															'startTime',
														)
													}
												/>
											</div>
											<div className='col-md-4'>
												<label htmlFor='time'>End Time</label>
												<input
													type='time'
													className='form-control'
													value={availability.time.endTime}
													onChange={(e) =>
														handleAvailabilityChange(
															formIndex,
															index,
															'time',
															e.target.value,
															'endTime',
														)
													}
												/>
											</div>
										</div>
									))}
								</div>
							</div>
							<div className='row col-12 mt-4 d-flex flex-column row-gap-2'>
								<p className='fw-bold'>Select Masseuses</p>
								{masseuses && masseuses?.length > 0 ? (
									<div className='d-flex w-100 justify-content-between align-items-center column-gap-3'>
										<button
											type='button'
											className='btn btn-primary'
											onClick={handlePrevious}
											disabled={currentIndex === 0}>
											<GrPrevious />
										</button>
										<div className='d-flex row col-10 column-gap-2'>
											{masseuses
												?.slice(currentIndex, currentIndex + itemsToShow)
												.map((masseuse, index) => (
													/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
													<div
														className={`card d-flex flex-column ${
															serviceForms[
																formIndex
															].masseuses.includes(masseuse.id)
																? 'border border-2 border-primary'
																: ''
														}`}
														style={{
															width: '40%',
															cursor: 'pointer',
															height: '300px',
														}}
														/* eslint-disable-next-line react/no-array-index-key */
														key={index}
														/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
														onClick={() =>
															handleCardClick(formIndex, masseuse.id)
														}>
														<img
															src={`${process.env.REACT_APP_MEDIA_URL}${masseuse?.profileImage}`}
															className='card-img-top'
															alt={masseuse.firstName}
														/>
														<div className='card-body d-flex flex-column row-gap-1'>
															<h5 className='card-title'>
																{masseuse.firstName}{' '}
																{masseuse.lastName}
															</h5>
															<label
																htmlFor='text'
																className='card-text'>
																Rating:{' '}
																{masseuse.rating
																	? masseuse.rating
																	: 'NA'}
															</label>
															<label
																htmlFor='card'
																className='card-text'>
																Gender: {masseuse.gender}
															</label>
														</div>
													</div>
												))}
										</div>
										<button
											type='button'
											className='btn btn-primary'
											onClick={handleNext}
											disabled={
												currentIndex >= masseuses.length - itemsToShow
											}>
											<GrNext />
										</button>
									</div>
								) : (
									<div className='row col-12 text-center p-2'>
										<p className='fw-bold'>No masseuses have been added yet.</p>
									</div>
								)}
							</div>
							<div className='col-12 g-4 mt-4 col-8'>
								<button
									type='button'
									className='d-flex align-items-center justify-content-center col-12 py-3 btn btn-info border-1'
									onClick={() => {
										addServiceHandler(service);
									}}>
									Save
								</button>
							</div>
						</div>
					))}
				</div>
				<div className='row col-10 g-4 mt-2'>
					<button
						type='button'
						className='d-flex align-items-center justify-content-center col-12 py-3 btn btn-primary border-1'
						onClick={addServiceForm}>
						+ Add
					</button>
				</div>
				<div className='mt-5 row col-11 d-flex justify-content-between'>
					<div className='col-3'>
						<AntdButton
							className='btn btn-danger border-1 px-5 py-2'
							onClick={() => {
								dispatch(setOnBoardingStep(2));
							}}>
							Previous
						</AntdButton>
					</div>
					<div className='col-3'>
						<AntdButton
							className='btn btn-success border-1 px-5 py-2'
							onClick={handleThirdStepSubmit}>
							Next
						</AntdButton>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnBoardServiceManagement;
