// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */

export const validateName = (name: string) => {
	const namePattern = /^[A-Za-z]{3}/;
	return namePattern.test(name);
};

export const EmailValidation = (email: string): Boolean => {
	return /\S+@\S+\.\S+/.test(email);
};

export const PasswordValidation = (password: string): Boolean => {
	return /^\S*$/.test(password);
};

export const daysOfWeek = [
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
	'Sunday',
];
