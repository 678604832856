// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../redux/store';
import { usePostOnBoardingMutation } from '../../../../services/OnBoardingApis/OnBoardingApi';
import { OnBoardingResponse } from '../../../../services/OnBoardingApis/type';
import { setOnBoardingStep } from '../../../../redux/slice';
import { useSendEnvelopesMutation } from '../../../../services/DashboardApis/dashboardApi';
import { ApiResponse } from '../../../../services/DashboardApis/type';

const OnBoardDocusign = () => {
	const dispatch = useDispatch();
	const { token, userData } = useSelector((state: RootState) => state.admin);
	const [masseuseId, setMasseuseId] = useState<string | undefined>();
	const [isLoading, setIsLoading] = useState(false);

	const [postOnBoarding] = usePostOnBoardingMutation();
	const [sendEnvelopes] = useSendEnvelopesMutation();

	useEffect(() => {
		if (userData) {
			setMasseuseId(userData.id);
			sendEnvelopesHandler();
		}
	}, [userData]);

	const sendEnvelopesHandler = async () => {
		await sendEnvelopes({})
			.unwrap()
			.then((res) => {
				const { result, message: msg } = res as ApiResponse;
				if (result) {
					message.success(msg);
					handleFourthStepSubmit();
					dispatch(setOnBoardingStep(5));
				}
			})
			.catch((err) => {
				console.log(err);
				message.error('Something went wrong', err.message);
			});
	};

	const handleFourthStepSubmit = () => {
		try {
			postOnBoarding({
				isOnboard: true,
				onboardingStep: 4,
			})
				.unwrap()
				.then(async (res) => {
					const { result, message: msg } = res as OnBoardingResponse;
					if (result) {
						message.success(msg);
						sendEnvelopesHandler();
					} else {
						message.error(msg);
					}
				})
				.catch((error) => {
					message.error(error.data.message);
				});
		} catch (error) {
			message.error('something went wrong');
			console.log(error);
		}
	};

	return (
		<div
			style={{ height: '100vh' }}
			className='row col-12 border d-flex justify-content-center'>
			<div className='d-flex flex-column col-10 align-items-center justify-content-center py-5'>
				<Spin spinning={isLoading} size='large' tip='Processing...'>
					<p className='fs-3 mb-6 fw-semibold col-10 text-start'>Loading...</p>
				</Spin>
			</div>
		</div>
	);
};

export default OnBoardDocusign;
