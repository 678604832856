import React, { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { requestPermission, onMessageListener } from '../../../firebase/firebase';

const Notification: React.FC = () => {
	const [notifications, setNotifications] = useState<{ title: string; body: string }[]>([]);
	const [show, setShow] = useState(false);

	useEffect(() => {
		requestPermission();

		const unsubscribe = onMessageListener((payload: any) => {
			setShow(true);
			setNotifications((prevNotifications) => [
				...prevNotifications,
				{
					title: payload?.notification?.title,
					body: payload?.notification?.body,
				},
			]);

			toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
				duration: 2000,
				position: 'top-right',
			});
		});

		return () => {
			if (typeof unsubscribe === 'function') {
				unsubscribe();
			}
		};
	}, []);

	return (
		<div>
			<Toaster />
		</div>
	);
};

export default Notification;
