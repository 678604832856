import adminApi from '../adminApi';
import { OnBoardingRequestBody } from './type';

export interface PreSignUrlData {
	filename: string;
	keyType: string;
}

const OnBoardingApi = adminApi.injectEndpoints({
	endpoints: (builder: any) => ({
		getPresignedUrl: builder.mutation({
			query: (payload: PreSignUrlData) => ({
				url: 'presigned-url',
				method: 'POST',
				body: payload,
			}),
		}),
		getOnBoarding: builder.mutation({
			query: () => ({
				url: 'massage-center/onboarding',
				method: 'GET',
			}),
		}),
		postOnBoarding: builder.mutation({
			query: (payload: OnBoardingRequestBody) => ({
				url: 'massage-center/onboarding',
				method: 'POST',
				body: payload,
			}),
		}),
	}),
});

export default OnBoardingApi;
export const { useGetPresignedUrlMutation, useGetOnBoardingMutation, usePostOnBoardingMutation } =
	OnBoardingApi;
