export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	serviceManagement: {
		id: 'services',
		text: 'Services',
		path: 'service-management/services',
		icon: 'settings',
		subMenu: null,
	},
	masseuseManagement: {
		id: 'masseuseManagement',
		text: 'Masseuse Management',
		path: 'masseuse-management',
		icon: 'spa',
		SubMenu: null,
	},
	bookingManagement: {
		id: 'bookingManagement',
		text: 'Booking Management',
		path: 'booking-management',
		icon: 'article',
		subMenu: {
			bookings: {
				id: 'bookings',
				text: 'Upcoming Booking',
				path: 'booking-management/bookings',
				icon: 'Book Online',
			},
			pastBookings: {
				id: 'pastBookings',
				text: 'Past Bookings',
				path: 'booking-management/past-bookings',
				icon: 'article',
			},
			bookingsCalender: {
				id: 'bookingsCalender',
				text: 'Bookings Calender',
				path: 'booking-management/bookings-calender',
				icon: 'Event',
			},
			bookingDetail: {
				id: 'bookingDetail',
				text: 'Booking Detail',
				path: 'booking-management/booking-detail',
				hide: true,
			},
		},
	},
	reviewsManagement: {
		id: 'reviewsManagement',
		text: 'Reviews Management',
		path: 'reviews-management',
		icon: 'rate_review',
		subMenu: null,
	},
	paymentManagement: {
		id: 'financeManagement',
		text: 'Finance Management',
		path: 'finance-management',
		icon: 'Paid',
		subMenu: null,
	},
	promotions: {
		id: 'promotions',
		text: 'Promotions',
		path: 'promotions',
		icon: 'Campaign',
		subMenu: {
			generalPromotions: {
				id: 'generalPromotions',
				text: 'General Promotions',
				path: 'general-promotions',
				icon: 'Campaign',
			},
		},
	},
	notificationManagement: {
		id: 'notificationManagement',
		text: 'Notifications',
		path: 'notification-management',
		icon: 'Notifications Active',
		subMenu: null,
	},
	profileManagement: {
		id: 'profileManagement',
		text: 'Profile Management',
		path: 'profile-management',
		icon: 'account_circle',
	},
	helpAndSupport: {
		id: 'helpAndSupport',
		text: 'Help & Support',
		path: 'help-&-support',
		icon: 'help',
		subMenu: {
			faq: {
				id: 'FAQ',
				text: `FAQ's`,
				path: `FAQ's`,
			},
			contactQueries: {
				id: 'contactQueries',
				text: 'Contact Queries',
				path: 'contact-queries',
			},
		},
	},
	masseuseDetails: {
		id: 'masseuseDetails',
		text: 'Masseuse Details',
		path: 'masseuse-details',
		icon: 'account_circle',
		hide: true,
	},
	invoiceDetails: {
		id: 'invoiceDetails',
		text: 'Invoice Detail',
		path: 'invoice-details',
		hide: true,
	},
	addBooking: {
		id: 'addBooking',
		text: 'Add Booking',
		path: 'booking-management/add-booking',
		hide: true,
	},
	// profileServiceManagement: {
	// 	id: 'profileServiceManagement',
	// 	text: 'Profile & Services Management',
	// 	path: 'profile-service-management',
	// 	icon: 'manage_accounts',
	// 	subMenu: {
	// 		profileManagement: {
	// 			id: 'profileManagement',
	// 			text: 'Profile Management',
	// 			path: 'profile-service-management/profile-management',
	// 			icon: 'account_circle',
	// 		},
	// 		serviceManagement: {
	// 			id: 'serviceManagement',
	// 			text: 'Service Management',
	// 			path: 'profile-service-management/service-management',
	// 			icon: 'settings',
	// 		},
	// 		serviceDetails: {
	// 			id: 'serviceDetails',
	// 			text: 'Service Details',
	// 			path: 'profile-service-management/service-management/service-details',
	// 			hide: true,
	// 		},
	// 		featureManagement: {
	// 			id: 'featureManagement',
	// 			text: 'Feature Management',
	// 			path: 'profile-service-management/feature-management',
	// 			icon: 'widgets',
	// 		},
	// 		featureReview: {
	// 			id: 'featureReview',
	// 			text: 'Featured Service Review',
	// 			path: 'profile-service-management/feature-review',
	// 			icon: 'featured_play_list',
	// 		},
	// 		HabitKeyRequest: {
	// 			id: 'HabitKeyRequest',
	// 			text: 'Habit Key Request',
	// 			path: 'habit-key-request',
	// 			icon: 'offline_bolt',
	// 		},
	// 		FeaturedHabitKey: {
	// 			id: 'FeaturedHabitKey',
	// 			text: 'Habit Key Detail',
	// 			path: 'featured-habit-key',
	// 			icon: 'featured_play_list',
	// 		},
	// 	},
	// },
	serviceDetail: {
		id: 'serviceDetail',
		text: 'Service Detail',
		path: 'service-management/service-detail',
		icon: 'account_circle',
		hide: true,
	},
	massageDetails: {
		id: 'massageDetails',
		text: 'Massage Details',
		path: 'massage-details',
		hide: true,
	},
	addAMassage: {
		id: 'addAMassage',
		text: 'Add Massage',
		path: 'add-massage',
		hide: true,
	},
	userManagement: {
		id: 'userManagement',
		text: 'User Management',
		path: 'user-management',
		icon: 'Group',
		hide: true,
	},
	userDetail: {
		id: 'userDetail',
		text: 'User Detail',
		path: 'user-detail',
		hide: true,
	},
	massageCenterReviewDetail: {
		id: 'massageCenterReviewDetail',
		text: 'Massage-center Detail',
		path: 'reviews-management/massage-center-review-detail',
		hide: true,
	},
	masseuseReviewDetail: {
		id: 'masseuseReviewDetail',
		text: 'Masseuse Detail',
		path: 'reviews-management/masseuse-review-detail',
		hide: true,
	},
	serviceReviewDetail: {
		id: 'serviceReviewDetail',
		text: 'Service Detail',
		path: 'reviews-management/service-review-detail',
		hide: true,
	},
	pastReviewDetail: {
		id: 'pastReviewDetail',
		text: 'Past-review Detail',
		path: 'reviews-management/past-review-detail',
		hide: true,
	},
};

export const onBoardingMenu = {
	onBoarding: {
		id: 'onBoarding',
		text: 'On Boarding',
		path: '/onBoarding',
		icon: '',
		subMenu: null,
		hide: true,
	},
};

export const reviewMenu = {
	blank: {
		id: 'review',
		text: 'Review',
		path: '/review',
		icon: 'check_box_outline_blank',
		hide: true,
	},
};

export const demoPagesMenu = {
	login: {
		id: 'login',
		text: 'Logout',
		path: '/login',
		icon: 'Logout',
	},
	// signUp: {
	// 	id: 'signUp',
	// 	text: 'Sign Up',
	// 	path: 'auth-pages/sign-up',
	// 	icon: 'PersonAdd',
	// },
	// page404: {
	// 	id: 'Page404',
	// 	text: '404 Page',
	// 	path: 'auth-pages/404',
	// 	icon: 'ReportGmailerrorred',
	// },
};
