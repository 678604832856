import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
	apiKey: 'AIzaSyDGBkm3Wd7EwqHvvJ0azbtj0IEF0Tl2l-I',
	authDomain: 'massagekey-1aa75.firebaseapp.com',
	projectId: 'massagekey-1aa75',
	storageBucket: 'massagekey-1aa75.appspot.com',
	messagingSenderId: '302636205587',
	appId: '1:302636205587:web:dbc9cf04769d0fe11061e6',
	measurementId: 'G-4MYGDEP61J',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
	// console.log('Requesting User Permission......');
	// eslint-disable-next-line consistent-return
	Notification.requestPermission().then((permission) => {
		if (permission === 'granted') {
			// console.log('Notification User Permission Granted.');
			return getToken(messaging, {
				vapidKey:
					'BJD5OPoRGCjSl5krhIBLArjCPpJUSWGlpuFE3YCJ0VJHpWbEl8n6Tae325G5LAhK4Md7XG7B0TVKiawfgKIMY3g',
			})
				.then((currentToken) => {
					if (currentToken) {
						console.log('Client Token: ', currentToken);
					} else {
						console.log('Failed to generate the app registration token.');
					}
				})
				.catch((err) => {
					console.log('An error occurred when requesting to receive the token.', err);
				});
		}
	});
};

requestPermission();

export const onMessageListener = (callback: any) => {
	const unsubscribe = onMessage(messaging, (payload: any) => {
		console.log(messaging, payload, '-->foreground message');
		callback(payload);
	});

	return unsubscribe;
};
