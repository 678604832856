import adminApi from '../adminApi';
import { LoginRequestBody, SignupRequestBody } from './types';

const AuthApi = adminApi.injectEndpoints({
	endpoints: (builder: any) => ({
		postLogin: builder.mutation({
			query: (payload: LoginRequestBody) => ({
				url: 'massage-center/login',
				method: 'POST',
				body: payload,
			}),
		}),
		postSignup: builder.mutation({
			query: (payload: SignupRequestBody) => ({
				url: 'massage-center/signup',
				method: 'POST',
				body: payload,
			}),
		}),
	}),
});

export const { usePostLoginMutation, usePostSignupMutation } = AuthApi;
