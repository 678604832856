import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../assets/svg/LoginLogo.svg';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	return (
		<div className='brand'>
			<div className='brand-logo d-flex'>
				<h1 className='brand-title'>
					{asideStatus && (
						<Link to='/' aria-label='Logo'>
							<img src={Logo} alt='logo' height={42} width={38} />
						</Link>
					)}
				</h1>
				{asideStatus && (
					<p
						style={{
							marginLeft: '0.5rem',
							position: 'relative',
							top: '0.65rem',
							fontWeight: 'bold',
						}}>
						Massage Key
					</p>
				)}
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon icon='FirstPage' className='brand-aside-toggle-close' />
				<Icon icon='LastPage' className='brand-aside-toggle-open' />
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
