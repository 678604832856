import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import { dashboardPagesMenu, onBoardingMenu, reviewMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideHead } from '../../../layout/Aside/Aside';
import Button from '../../../components/bootstrap/Button';
import { setIsBoarding, setLogout, setUserData } from '../../../redux/slice';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(setLogout());
	};

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<>
					<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
					<Navigation menu={onBoardingMenu} id='aside-onBoarding-page' />
					<Navigation menu={reviewMenu} id='aside-onBoarding-page' />
					{/* <Button
						color='danger'
						icon='Logout'
						aria-label='logout'
						onClick={handleLogout}
						className='mt-auto mx-auto w-80'
						style={{ width: '90%' }}>
						Logout
					</Button> */}
					{asideStatus ? (
						<Button
							color='danger'
							icon='Logout'
							aria-label='logout'
							onClick={handleLogout}
							className='mt-auto mx-auto w-80'
							style={{ width: '90%' }}>
							Logout
						</Button>
					) : (
						<Button
							color='danger'
							icon='Logout'
							aria-label='logout'
							onClick={handleLogout}
							className='mt-auto mx-auto w-80'
						/>
					)}
				</>
			</AsideBody>
		</Aside>
	);
};

export default DefaultAside;
