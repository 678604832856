// eslint-disable-next-line prettier/prettier, eslint-comments/disable-enable-pair
/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, FC } from 'react';
import { Upload, message, Radio, Button as AntdButton } from 'antd';
import axios from 'axios';
import { RcFile, UploadChangeParam } from 'antd/es/upload/interface';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import { useAddMasseuseMutation } from '../../../../services/DashboardApis/dashboardApi';
import {
	MasseusesOnBoardData,
	MasseuseDetailResponse,
} from '../../../../services/DashboardApis/type';
import { useGetPresignedUrlMutation } from '../../../../services/OnBoardingApis/OnBoardingApi';
import { PresignedResponse } from '../../../../services/DashboardApis/type';
import { setOnBoardingStep } from '../../../../redux/slice';
import { RootState } from '../../../../redux/store';

interface Props {
	isFrom: string;
	// eslint-disable-next-line react/require-default-props
	setModal?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
}

const OnBoardMasseuseManagement: FC<Props> = ({ isFrom, setModal }) => {
	const dispatch = useDispatch();
	const { token, userData } = useSelector((state: RootState) => state.admin);
	const [masseuseId, setMasseuseId] = useState<string | undefined>();
	const [fileData, setFileData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isSaveComplete, setIsSaveComplete] = useState(false);
	const [docs] = useState<string[]>([]);
	const [schedules, setSchedules] = useState([
		{
			day: 'Monday',
			times: [{ startTime: '', endTime: '' }],
		},
	]);

	const daysOfWeek = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];
	const [masseuseData, setMasseuseData] = useState<MasseusesOnBoardData[]>([
		{
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			gender: '',
			number: '',
			description: '',
			profileImage: '',
			experience: '',
			slots: schedules,
		},
	]);

	const [getPresignedUrl] = useGetPresignedUrlMutation();
	const [addMasseuse] = useAddMasseuseMutation();

	const uploadButton = (
		<button style={{ border: 0, background: 'none' }} type='button'>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</button>
	);

	const addMasseuseRow = () => {
		const newMasseuse = {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			gender: '',
			description: '',
			profileImage: '',
			number: '',
			experience: '',
			slots: schedules,
		};
		setMasseuseData([...masseuseData, newMasseuse]);
		setIsSaveComplete(false);
	};

	const uploadDocumentsHandler = async (info: UploadChangeParam) => {
		const selectedDoc = info.file;
		setFileData(info.fileList);

		if (!selectedDoc) {
			message.error('Selected document is undefined');
			return;
		}

		await getPresignedUrl({
			filename: info.file.name,
			keyType: 'masseuseProfile',
		})
			.unwrap()
			.then(async (response: any) => {
				updateDocumentHandler({
					presignedData: response.data,
					selectedDoc: selectedDoc as RcFile,
				});
			})
			.catch((err: any) => {
				message.error(err.error);
			});
	};

	const handleAddTimeSlot = (index: number) => {
		const newSchedules = [...schedules];
		newSchedules[index].times.push({ startTime: '', endTime: '' });
		setSchedules(newSchedules);
	};

	const handleAddSchedule = () => {
		setSchedules([...schedules, { day: '', times: [{ startTime: '', endTime: '' }] }]);
	};

	const handleScheduleChange = (
		index: number,
		field: 'day' | 'times',
		value: string | { startTime: string; endTime: string }[],
	) => {
		const newSchedules = [...schedules];

		if (field === 'day' && typeof value === 'string') {
			newSchedules[index][field] = value;
		} else if (field === 'times' && Array.isArray(value)) {
			newSchedules[index][field] = value;
		}

		setSchedules(newSchedules);
	};

	const handleTimeChange = (
		scheduleIndex: number,
		timeIndex: number,
		field: 'startTime' | 'endTime',
		value: string,
	) => {
		const newSchedules = [...schedules];
		newSchedules[scheduleIndex].times[timeIndex][field] = value;
		setSchedules(newSchedules);
	};

	// *************************************************************
	// NOTE: Helper Methods
	// *************************************************************

	const updateDocumentHandler = async ({
		presignedData,
		selectedDoc,
	}: {
		presignedData: PresignedResponse;
		selectedDoc: RcFile;
		// eslint-disable-next-line consistent-return
	}) => {
		if (!presignedData && !selectedDoc) {
			return message.error('Something went wrong please try again later');
		}

		setMasseuseData((prevData) =>
			prevData.map((masseuse) => ({
				...masseuse,
				profileImage: presignedData?.filename,
			})),
		);

		await axios
			.put(`${presignedData.presignedUrl}`, selectedDoc)
			.then(() => {
				message.success('Successfully uploaded');
			})
			.catch(() => {
				message.error('Something went wrong...');
			});
	};

	const addMasseuseHandler = async (index: number) => {
		setIsLoading(true);
		await addMasseuse({
			centerId: masseuseId,
			payload: masseuseData[index],
		})
			.unwrap()
			.then((response) => {
				const { result, message: msg } = response as MasseuseDetailResponse;
				if (result) {
					message.success(msg);
					setIsLoading(false);
					setIsSaveComplete(true);
					setMasseuseData([
						{
							firstName: '',
							lastName: '',
							email: '',
							password: '',
							gender: '',
							experience: '',
							description: '',
							number: '',
							profileImage: '',
							slots: [
								{
									day: 'Monday',
									times: [{ startTime: '', endTime: '' }],
								},
							],
						},
					]);
					setFileData([]);
					setSchedules([
						{
							day: 'Monday',
							times: [{ startTime: '', endTime: '' }],
						},
					]);
					if (setModal) {
						setModal(false);
					}
				}
			})
			.catch((error) => {
				message.error(error?.data?.message);
				setIsLoading(false);
				if (setModal) {
					setModal(false);
				}
			});
	};

	useEffect(() => {
		setMasseuseData((prevData) =>
			prevData.map((masseuse) => ({
				...masseuse,
				slots: schedules,
			})),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [schedules]);

	useEffect(() => {
		if (userData) {
			setMasseuseId(userData.id);
		}
	}, [userData]);

	return (
		<div className='row col-12 d-flex justify-content-center'>
			<div className='d-flex flex-column col-10 align-items-center justify-content-center py-5'>
				<p className='fs-3 mb-6 fw-semibold col-10 text-start'>Add Masseuse</p>
				<div className='row col-12 d-flex row-gap-4 justify-content-center '>
					{masseuseData?.map((owner, index) => (
						<div className='d-flex flex-column  align-items-center col-10 px-3 py-4 border border-2 rounded-2'>
							<React.Fragment key={index}>
								<div className='row g-4 col-12'>
									<div className='col-6'>
										<p className='m-lg-2 fw-bold'>Masseuse Picture</p>
										<Upload
											accept='.jpg, .jpeg, .png'
											listType='picture-card'
											beforeUpload={() => false}
											maxCount={1}
											rootClassName='w-10'
											onChange={(info) => uploadDocumentsHandler(info)}>
											{/* {masseuseData.profileImage !== '' ? null : uploadButton} */}
											{fileData.length >= 1 ? null : uploadButton}
										</Upload>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Email</p>
										<div className='col-12'>
											<FormGroup id='email' isFloating>
												<Input
													value={owner.email}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>,
													) => {
														const newMasseuseData = [...masseuseData];
														newMasseuseData[index].email =
															e.target.value;
														setMasseuseData(newMasseuseData);
													}}
													placeholder='Email'
													type='email'
													name='email'
													id='email'
												/>
											</FormGroup>
										</div>
									</div>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Phone Number</p>
										<div className='col-12'>
											<FormGroup id='firstName' isFloating>
												<Input
													value={owner.number}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>,
													) => {
														const newMasseuseData = [...masseuseData];
														newMasseuseData[index].number =
															e.target.value;
														setMasseuseData(newMasseuseData);
													}}
												/>
											</FormGroup>
										</div>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>First Name</p>
										<div className='col-12'>
											<FormGroup id='firstName' label='First Name' isFloating>
												<Input
													value={owner.firstName}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>,
													) => {
														const newMasseuseData = [...masseuseData];
														newMasseuseData[index].firstName =
															e.target.value;
														setMasseuseData(newMasseuseData);
													}}
												/>
											</FormGroup>
										</div>
									</div>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Last Name</p>
										<div className='col-12'>
											<FormGroup id='lastName' isFloating>
												<Input
													value={owner.lastName}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>,
													) => {
														const newMasseuseData = [...masseuseData];
														newMasseuseData[index].lastName =
															e.target.value;
														setMasseuseData(newMasseuseData);
													}}
												/>
											</FormGroup>
										</div>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Password</p>
										<div className='col-12'>
											<FormGroup id='password' isFloating>
												<Input
													type='password'
													value={owner.password}
													onChange={(
														e: React.ChangeEvent<HTMLInputElement>,
													) => {
														const newMasseuseData = [...masseuseData];
														newMasseuseData[index].password =
															e.target.value;
														setMasseuseData(newMasseuseData);
													}}
												/>
											</FormGroup>
										</div>
									</div>
									<div className='col-6'>
										<p className='m-lg-1 fw-bold'>Gender</p>
										<div className='col-12 d-flex jus'>
											<Radio.Group
												onChange={(e) => {
													const newMasseuseData = [...masseuseData];
													newMasseuseData[index].gender = e.target.value;
													setMasseuseData(newMasseuseData);
												}}
												value={owner.gender}>
												<Radio value='Male'>Male</Radio>
												<Radio value='Female'>Female</Radio>
												<Radio value='Other'>Other</Radio>
											</Radio.Group>
										</div>
									</div>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<p className='m-lg-1 fw-bold'>Description</p>
									<FormGroup id='description'>
										<Textarea
											value={owner.description}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												const newMasseuseData = [...masseuseData];
												newMasseuseData[index].description = e.target.value;
												setMasseuseData(newMasseuseData);
											}}
										/>
									</FormGroup>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<p className='fw-bold'>Experience</p>
									<FormGroup id='experience'>
										<Textarea
											value={owner.experience}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												const newMasseuseData = [...masseuseData];
												newMasseuseData[index].experience = e.target.value;
												setMasseuseData(newMasseuseData);
											}}
										/>
									</FormGroup>
								</div>
								<div className='row g-4 mt-4 col-12'>
									<p className='fw-bold'>Slots</p>
									{schedules.map((schedule, scheduleIndex) => (
										<div
											key={scheduleIndex}
											className='d-flex flex-column align-items-center mb-1 p-3 border border-2 rounded-2'>
											<div className='row col-12 mb-2'>
												<div className='col-md-3'>
													<label htmlFor='day'>Day</label>
													<select
														className='form-select'
														value={schedule.day}
														onChange={(e) =>
															handleScheduleChange(
																scheduleIndex,
																'day',
																e.target.value,
															)
														}>
														<option value=''>Select Day</option>
														{daysOfWeek.map((day) => (
															<option key={day} value={day}>
																{day}
															</option>
														))}
													</select>
												</div>
											</div>
											<p className='row col-12 fw-bold'>Times</p>
											<div className='d-flex flex-column align-items-center row-gap-3 row g-4 mt-4 p-3 col-12 border border-2 rounded'>
												{schedule.times.map((timeSlot, timeIndex) => (
													<div
														key={timeIndex}
														className='row  border border-1 rounded-2 p-2'>
														<div className='col-md-3'>
															<label htmlFor='select'>
																Start Time
															</label>
															<input
																type='time'
																className='form-control'
																value={timeSlot.startTime}
																onChange={(e) =>
																	handleTimeChange(
																		scheduleIndex,
																		timeIndex,
																		'startTime',
																		e.target.value,
																	)
																}
															/>
														</div>
														<div className='col-md-3'>
															<label htmlFor='select'>End Time</label>
															<input
																type='time'
																className='form-control'
																value={timeSlot.endTime}
																onChange={(e) =>
																	handleTimeChange(
																		scheduleIndex,
																		timeIndex,
																		'endTime',
																		e.target.value,
																	)
																}
															/>
														</div>
														<div className='col-md-3 d-flex align-items-end'>
															<button
																type='button'
																className='btn btn-primary'
																onClick={() =>
																	handleAddTimeSlot(scheduleIndex)
																}>
																+ Add Slot
															</button>
														</div>
													</div>
												))}
											</div>
										</div>
									))}
									<button
										type='button'
										className='col-3 btn btn-success'
										onClick={handleAddSchedule}>
										+ Add More Day
									</button>
								</div>
								<div className='col-12 g-4 mt-4 col-8'>
									<AntdButton
										className='d-flex align-items-center col-12 py-4 btn btn-info border-1'
										onClick={() => addMasseuseHandler(index)}>
										Save
									</AntdButton>
								</div>
							</React.Fragment>
						</div>
					))}
				</div>
				{isFrom === 'onBoarding' && (
					<div className='row col-10 g-4 mt-2'>
						<AntdButton
							disabled={!isSaveComplete}
							className='d-flex align-items-center col-12 py-4 btn btn-primary border-1'
							onClick={addMasseuseRow}>
							+ Add
						</AntdButton>
					</div>
				)}
				{isFrom === 'onBoarding' && (
					<div className='mt-5 row col-11 d-flex justify-content-between'>
						<button
							type='button'
							className='col-3 btn btn-danger border-1 px-5 py-3'
							onClick={() => {
								dispatch(setOnBoardingStep(1));
							}}>
							Previous
						</button>
						<button
							disabled={!isSaveComplete}
							type='button'
							className='col-3 btn btn-primary border-1 px-5 py-3'
							onClick={() => {
								dispatch(setOnBoardingStep(3));
							}}>
							Next
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default OnBoardMasseuseManagement;
